<template>
    <div class="rules">
        <div class="content">
            <transition name="fade" mode="out-in">
                <div class="c">
                    <div class="c-info-block">
                        <h1 class="c-title">Правила проекта</h1>
                        <p class="c-info-text">
                            Правила регулируют процессы человеческого поведения. Без них сложно обходиться и ценность их
                            несомненна. <br>Правила участвуют в формировании современного общества в которым мы
                            живем.<br>
                            Обычаи и законы тоже правила, но закреплённые на письменном уровне или на уровне истории.
                        </p>
                    </div>

                    <div class="c-frame">
                        <div class="apreloader" v-if="!rules.length > 0"/>
                        <div class="c-concrete-block" v-else>
                            <div class="spoiler" v-for="ruleset in rules">
                                <div class="title-block" v-if="!ruleset.show" @click="rulesetOpeningControl(ruleset, 'open')" style="cursor: pointer">
                                    <p class="spoiler-title">{{ ruleset.head }}</p>
                                </div>
                                <div class="title-block" v-else>
                                    <p class="spoiler-title" style="cursor: text;"
                                       :class="{'isActive':ruleset.show }">{{ ruleset.head }}</p>

                                    <div class="close-spoiler-block" v-if="ruleset.show"
                                         @click="rulesetOpeningControl(ruleset, 'close')">X
                                    </div>
                                </div>
                                <div class="spoiler-border"
                                     v-if="ruleset.show"></div>
                                    <div v-show="ruleset.show">
                                        <p v-for="info in ruleset.info" style="cursor: text;">{{ info }}</p>
                                        <div v-for="rule in ruleset.rules">
                                            <p style="cursor: text;">{{ rule.type === "rule" ? rule.number + ". " : rule.key + " - " }}
                                                {{ rule.main[0] }}</p>
                                            <p v-for="descrp in rule.main.slice(1)" style="cursor: text;">{{ descrp }}</p>
                                            <p v-for="info in rule.info" style="color: #0a93d3; cursor: text;">{{ info }}</p>
                                            <p v-for="pun in rule.pun" style="color: #e22f2f; cursor: text">Наказание:
                                                {{ pun }}</p>
                                        </div>
                                    </div>
                            </div>

                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>

import {PreloaderMixin} from "../mixins/PreloaderMixin";
import {SpoilerOpeningMixin} from "../mixins/SpoilerOpeningMixin";

export default {
    name: "Rules",
    mixins: [PreloaderMixin, SpoilerOpeningMixin],

    metaInfo: {
        title: 'GreatRay',
        titleTemplate: '%s: Правила проекта',
    },

    data() {
        return {
            rules: [],
            isActive: false
        }
    },

    methods: {
        getRules() {
            const axios = require('axios');
            axios.get('/api/rules').then(data => {
                this.preloaderStop('.apreloader')
                data.data.forEach(i => {
                    i.show = false;
                    this.rules.push(i);
                })
            })
        },
    },


    created() {
        this.getRules()
    },

    mounted() {
        this.preloaderStartLk('.apreloader')
    }
}

</script>

<style scoped lang="scss">

@import "src/assets/scss/animations";
@import "src/assets/scss/mixins";
@import "src/assets/scss/vars";

.content {
    @include flexible();
    width: 100vw;

    .c {
        @include simple-ctrr();
        @include in-page-preloader();
    }
}

@include ct-n-rp-transitions();

</style>
