export const SpoilerOpeningMixin = {
    methods: {
        rulesetOpeningControl(ruleset, operation) {
            if (!ruleset.show && operation === 'open') {
                ruleset.show = true
            }

            if (ruleset.show && operation === 'close') {
                ruleset.show = false
            }
        },
    }
}