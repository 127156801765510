import { render, staticRenderFns } from "./Rules.vue?vue&type=template&id=61405af0&scoped=true&"
import script from "./Rules.vue?vue&type=script&lang=js&"
export * from "./Rules.vue?vue&type=script&lang=js&"
import style0 from "./Rules.vue?vue&type=style&index=0&id=61405af0&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61405af0",
  null
  
)

export default component.exports